import React from "react";
import "twin.macro";
import { StaticImage } from "gatsby-plugin-image";
import {
  BlankPageNoImage,
  LocationBlock,
  ProseWrapper,
} from "../components/common";
import { SEO } from "../components";

const intro = `
Micro suction is the preferred and safest method of removing wax build up or blockages from the ear canals. Wax is a natural substance which helps lubricate the ear canal and protect the ear from foreign bodies, however, difficulties can arise if the normal and natural migration of the wax out of the ear canal is impeded.
<br /> <br />
Wax blockages can occur naturally but can also be encouraged by the use of hearing aids, headphones, cotton buds or hotter climates. If untreated a wax blockage can cause feelings of discomfort, irritation and a reduction in hearing.
`;

const MicroSuction = () => {
  return (
    <>
      <SEO title="Micro Suction Wax Removal" />
      <BlankPageNoImage
        header="Micro suction wax removal"
        intro={intro}
        ariaLabel="Micro suction wax removal"
      >
        <ProseWrapper>
          <StaticImage
            src="../images/wax_removal.jpg"
            alt="Diagram of the ear"
            quality={90}
          />
          <div>
            <h4>Micro Suction Wax Removal</h4>
            <p>
              Micro suction wax removal is done using a small instrument which
              is inserted safely into the ear canal under microscopic
              examination so that the audiologist can see clearly. This
              instrument is like a small vacuum which will very gently remove
              the wax. The procedure is not painful but mild discomfort can be
              experienced depending on the sensitivity of the ear canal and the
              amount and texture of the wax present. If it is not possible to
              remove the wax in one appointment the audiologist will book a
              follow up appointment to finish the procedure at a later date.
            </p>
          </div>
        </ProseWrapper>
      </BlankPageNoImage>
      <LocationBlock />
    </>
  );
};

export default MicroSuction;
